import { Box, Container, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { memo } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { country } from 'config';
import visa from './images/verified-by-visa.png';
import masterCard from './images/mc-sc.png';
import sslSecuredMobile from './images/ssl-secured.png';
import fiveStarMobile from './images/five-star.png';
import { RoutesDefinition } from '../../../routing/constants/RoutesDefinition';
import { Logo } from '../Logo';
import facebookIcon from './images/social/facebook.svg';
import youtubeIcon from './images/social/youtube.svg';
import instagramIcon from './images/social/instagram.svg';
import linkedinIcon from './images/social/linkedin.svg';
import tiktokIcon from './images/social/tiktok.svg';

export const Footer = memo((): JSX.Element => {
  const classes = useStyle();
  const { t } = useTranslation();
  const currentYear = dayjs().format('YYYY');

  return (
    <Box className={classes.box}>
      <Container maxWidth="lg" className={classes.content}>
        <Link to={RoutesDefinition.dashboard} className={classes.logo}>
          <Logo
            alt={t('logoLink')}
            classes={{
              img: classes.logoImg
            }}
          />
        </Link>
        <div className={classes.row}>
          <div>
            <div>
              <a href={`tel:${t('layout.footer.phone')}`}>{t('layout.footer.phone')}</a>
              <a href={`mailto:${t('layout.footer.email')}`}>{t('layout.footer.email')}</a>
            </div>
            <div>
              <div>{t('layout.footer.week')}</div>
              <div>{t('layout.footer.saturday')}</div>
            </div>
          </div>
          <div>
            <div className={classes.paymentsService}>{t('layout.footer.paymentsService')}</div>
            <div className={classes.iconGroup}>
              <img src={visa} alt="" width="64px" height="36px" />
              <img src={masterCard} alt="" width="64px" height="26px" />
              <img src={sslSecuredMobile} alt="" width="64px" height="19px" />
              <img src={fiveStarMobile} alt="" width="63px" height="28px" />
            </div>
          </div>
        </div>
      </Container>
      <Container maxWidth="lg" className={classes.social}>
        <div className={classes.iconGroup}>
          <a rel="nofollow noreferrer" target="_blank" title="Facebook" href={t('layout.footer.facebook')}>
            <picture>
              <source srcSet={facebookIcon} type="" />
              <img
                width="13"
                height="12"
                src={facebookIcon}
                className={classes.socialIcon}
                alt=""
                decoding="async"
                loading="lazy"
              />
            </picture>
          </a>
          {['poland', 'germany'].includes(country) && (
            <a rel="nofollow noreferrer" target="_blank" title="YouTube" href={t('layout.footer.youtube')}>
              <picture>
                <source srcSet={youtubeIcon} type="" />
                <img
                  width="13"
                  height="12"
                  src={youtubeIcon}
                  className={classes.socialIcon}
                  alt=""
                  decoding="async"
                  loading="lazy"
                />
              </picture>
            </a>
          )}
          <a rel="nofollow noreferrer" target="_blank" title="Instagram" href={t('layout.footer.instagram')}>
            <picture>
              <source srcSet={instagramIcon} type="" />
              <img
                width="13"
                height="12"
                src={instagramIcon}
                className={classes.socialIcon}
                alt=""
                decoding="async"
                loading="lazy"
              />
            </picture>
          </a>
          {['poland'].includes(country) && (
            <a rel="nofollow noreferrer" target="_blank" title="TikTok" href={t('layout.footer.tiktok')}>
              <picture>
                <source srcSet={tiktokIcon} type="" />
                <img
                  width="13"
                  height="19"
                  src={tiktokIcon}
                  className={classes.socialIcon}
                  alt=""
                  decoding="async"
                  loading="lazy"
                />
              </picture>
            </a>
          )}
          {['germany'].includes(country) && (
            <a rel="nofollow noreferrer" target="_blank" title="LinkedIn" href={t('layout.footer.linkedin')}>
              <picture>
                <source srcSet={linkedinIcon} type="" />
                <img
                  width="13"
                  height="19"
                  src={linkedinIcon}
                  className={classes.socialIcon}
                  alt=""
                  decoding="async"
                  loading="lazy"
                />
              </picture>
            </a>
          )}
        </div>
        <div>{t('layout.footer.allRightsReserved', { year: currentYear })}</div>
      </Container>
    </Box>
  );
});

const useStyle = makeStyles((theme) => ({
  socialIcon: {
    width: '20px'
  },
  iconGroup: {
    display: 'flex',
    gap: '24px',
    alignItems: 'center'
  },
  paymentsService: {
    textAlign: 'center'
  },
  social: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #FFFFFF26',
    paddingTop: '24px',
    color: '#6D6D6D',
    fontSize: '12px',
    lineHeight: '18px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      gap: '8px'
    }
  },
  logoImg: {
    maxHeight: '32px'
  },
  logo: {
    lineHeight: 0
  },
  row: {
    display: 'flex',
    color: theme.customColors.white,
    fontSize: '14px',
    lineHeight: '21px',
    justifyContent: 'space-between',
    marginBottom: '22px',
    '&> div': {
      display: 'flex',
      flex: 1,
      '&:first-of-type': {
        display: 'flex',
        gap: '32px',
        '&> div': {
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          '&> a': {
            textDecoration: 'underline',
            color: theme.customColors.white
          }
        }
      },
      '&:nth-of-type(2)': {
        alignItems: 'end',
        flexDirection: 'column',
        gap: '8px'
      }
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '24px',
      '&> div': {
        '&:first-of-type': {
          justifyContent: 'space-between'
        },
        '&:nth-of-type(2)': {
          alignItems: 'center!important'
        }
      }
    }
  },
  box: {
    backgroundColor: theme.palette.secondary.main,
    padding: '2rem 0 1rem',
    position: 'relative'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '33px'
  }
}));
